import React from "react";
import { TECollapse } from "tw-elements-react";

const Faq = () => {
    const [activeElement, setActiveElement] = React.useState("");

    const handleClick = (value) => {
        if (value === activeElement) {
            setActiveElement("");
        } else {
            setActiveElement(value);
        }
    };

    return (
        <main>
            <div className="flex items-end justify-center mt-5 mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                </svg>
                <h1 className="text-4xl ms-2">FAQ</h1>
            </div>
            <h3 className="text-2xl mb-5">Perguntas frequentes:</h3>

            <section className="p-5 mb-5 md:mb-5 md:w-9/12 md:m-auto">
                <div className="" id="accordionExample">
                    <div className="rounded-t-lg border bg-white">
                        <h2 className="mb-0" id="headingOne">
                            <button
                                className={`${activeElement === "element1" &&
                                    `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:!text-primary-400`
                                    } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none bg-white text-`}
                                type="button"
                                onClick={() => handleClick("element1")}
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                Como faço para comprar?
                                <span
                                    className={`${activeElement === "element1"
                                        ? `rotate-[-180deg] -mr-1`
                                        : `rotate-0 fill-[#212529]  dark:fill-white`
                                        } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">

                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />

                                    </svg>
                                </span>
                            </button>
                        </h2>
                        <TECollapse
                            show={activeElement === "element1"}
                            className="!mt-0 !rounded-b-none !shadow-none"
                        >
                            <div className="px-5 py-4">
                                <strong>This is the first item's accordion body.</strong> Lorem
                                ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eu
                                rhoncus purus, vitae tincidunt nibh. Vivamus elementum egestas
                                ligula in varius. Proin ac erat pretium, ultricies leo at, cursus
                                ante. Pellentesque at odio euismod, mattis urna ac, accumsan
                                metus. Nam nisi leo, malesuada vitae pretium et, laoreet at lorem.
                                Curabitur non sollicitudin neque.
                            </div>
                        </TECollapse>
                    </div>
                </div>

                <div className="border bg-white">
                    <h2 className="mb-0" id="headingTwo">
                        <button
                            className={`${activeElement === "element2"
                                ? `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:!text-primary-400`
                                : `transition-none rounded-b-[15px]`
                                } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none bg-white text-azul`}
                            type="button"
                            onClick={() => handleClick("element2")}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            Quais as formas de pagamento?
                            <span
                                className={`${activeElement === "element2"
                                    ? `rotate-[-180deg] -mr-1`
                                    : `rotate-0 fill-[#212529] dark:fill-white`
                                    } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">

                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />

                                </svg>
                            </span>
                        </button>
                    </h2>
                    <TECollapse
                        show={activeElement === "element2"}
                        className="!mt-0 !rounded-b-none !shadow-none"
                    >
                        <div className="px-5 py-4">
                            <strong>This is the second item's accordion body.</strong> Lorem
                            ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eu
                            rhoncus purus, vitae tincidunt nibh. Vivamus elementum egestas
                            ligula in varius. Proin ac erat pretium, ultricies leo at, cursus
                            ante. Pellentesque at odio euismod, mattis urna ac, accumsan metus.
                            Nam nisi leo, malesuada vitae pretium et, laoreet at lorem.
                            Curabitur non sollicitudin neque.
                        </div>
                    </TECollapse>
                </div>

                <div className="rounded-b-lg border border-t-0 bg-white">
                    <h2 className="accordion-header mb-0" id="headingThree">
                        <button
                            className={`${activeElement === "element3"
                                ? `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:!text-primary-400`
                                : `transition-none rounded-b-[15px]`
                                } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none bg-white text-azul`}
                            type="button"
                            onClick={() => handleClick("element3")}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            Quais são os horários de atendimento?   
                            <span
                                className={`${activeElement === "element3"
                                    ? `rotate-[-180deg] -mr-1`
                                    : `rotate-0 fill-[#212529] dark:fill-white`
                                    } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">

                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />

                                </svg>
                            </span>
                        </button>
                    </h2>
                    <TECollapse
                        show={activeElement === "element3"}
                        className="!mt-0 !shadow-none"
                    >
                        <div className="px-5 py-4">
                            <strong>This is the third item's accordion body.</strong>Lorem ipsum
                            dolor sit amet, consectetur adipiscing elit. Vestibulum eu rhoncus
                            purus, vitae tincidunt nibh. Vivamus elementum egestas ligula in
                            varius. Proin ac erat pretium, ultricies leo at, cursus ante.
                            Pellentesque at odio euismod, mattis urna ac, accumsan metus. Nam
                            nisi leo, malesuada vitae pretium et, laoreet at lorem. Curabitur
                            non sollicitudin neque.
                        </div>
                    </TECollapse>
                </div>

                <div className="rounded-b-lg border border-t-0 bg-white">
                    <h2 className="accordion-header mb-0" id="headingThree">
                        <button
                            className={`${activeElement === "element4"
                                ? `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:!text-primary-400`
                                : `transition-none rounded-b-[15px]`
                                } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none bg-white text-azul`}
                            type="button"
                            onClick={() => handleClick("element4")}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            Como vejo minhas compras? 
                            <span
                                className={`${activeElement === "element4"
                                    ? `rotate-[-180deg] -mr-1`
                                    : `rotate-0 fill-[#212529] dark:fill-white`
                                    } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">

                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />

                                </svg>
                            </span>
                        </button>
                    </h2>
                    <TECollapse
                        show={activeElement === "element4"}
                        className="!mt-0 !shadow-none"
                    >
                        <div className="px-5 py-4">
                            <strong>This is the third item's accordion body.</strong>Lorem ipsum
                            dolor sit amet, consectetur adipiscing elit. Vestibulum eu rhoncus
                            purus, vitae tincidunt nibh. Vivamus elementum egestas ligula in
                            varius. Proin ac erat pretium, ultricies leo at, cursus ante.
                            Pellentesque at odio euismod, mattis urna ac, accumsan metus. Nam
                            nisi leo, malesuada vitae pretium et, laoreet at lorem. Curabitur
                            non sollicitudin neque.
                        </div>
                    </TECollapse>
                </div>

                <div className="rounded-b-lg border border-t-0 bg-white">
                    <h2 className="accordion-header mb-0" id="headingThree">
                        <button
                            className={`${activeElement === "element5"
                                ? `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:!text-primary-400`
                                : `transition-none rounded-b-[15px]`
                                } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none bg-white text-azul`}
                            type="button"
                            onClick={() => handleClick("element5")}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            Como faço meu cadastro? 
                            <span
                                className={`${activeElement === "element5"
                                    ? `rotate-[-180deg] -mr-1`
                                    : `rotate-0 fill-[#212529] dark:fill-white`
                                    } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">

                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />

                                </svg>
                            </span>
                        </button>
                    </h2>
                    <TECollapse
                        show={activeElement === "element5"}
                        className="!mt-0 !shadow-none"
                    >
                        <div className="px-5 py-4">
                            <strong>This is the third item's accordion body.</strong>Lorem ipsum
                            dolor sit amet, consectetur adipiscing elit. Vestibulum eu rhoncus
                            purus, vitae tincidunt nibh. Vivamus elementum egestas ligula in
                            varius. Proin ac erat pretium, ultricies leo at, cursus ante.
                            Pellentesque at odio euismod, mattis urna ac, accumsan metus. Nam
                            nisi leo, malesuada vitae pretium et, laoreet at lorem. Curabitur
                            non sollicitudin neque.
                        </div>
                    </TECollapse>
                </div>
            </section>

        </main >
    )
}

export default Faq;