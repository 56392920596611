import React from "react";
import { TECollapse } from "tw-elements-react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import lucro from '../assets/lucro.png'

const Services = () => {
    const [activeElement, setActiveElement] = React.useState("");

    const handleClick = (value) => {
        if (value === activeElement) {
            setActiveElement("");
        } else {
            setActiveElement(value);
        }
    }

    React.useEffect(() => {
        AOS.init();
    }, [])

    return (
        <main className="">

            <section className="bg-azul border-t shadow md:flex md:justify-center">
                <div className="md:grid md:grid-cols-2 md:gap-4 md:items-center md:p-5" data-aos="fade-right" data-aos-duration="800    ">
                    <div className="text-branco text-start">
                        <h1 className="ms-1 font-semibold text-3xl md:text-5xl p-5 pb-1 mb-2 underline decoration-1 ">Comece a lucrar de verdade!</h1>
                        <p className="ms-1 font-normal px-5">Todo o conhecimento de um dono de mercado de sucesso reunido em nossos serviços!</p>
                        <p className="ms-1 px-5 mt-3 font-light">A partir de <span className="text-verdeM text-2xl font-bold underline">R$29,90</span></p>
                        <p className="ms-1 px-5 mt-3 pb-5">Aproveite os melhores preços e torne seu negócio lucrativo!</p>
                    </div>
                    <div className="md:flex md:justify-center ">
                        <img src={lucro} className="w-6/12 hidden md:block"></img>
                    </div>
                </div>
            </section>

            <section className="container m-auto my-10 ">
                <div className="md:grid md:grid-cols-4 md:gap-5">
                    <div className="bg-white rounded-xl mb-10 md:mb-0" data-aos="fade-down" data-aos-duration="1200">
                        <div className="pt-3">
                            <svg class="size-8 me-3 ms-auto text-diamond" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="12" cy="9" r="6" />  <polyline points="9 14.2 9 21 12 19 15 21 15 14.2" transform="rotate(-30 12 9)" />  <polyline points="9 14.2 9 21 12 19 15 21 15 14.2" transform="rotate(30 12 9)" /></svg>
                        </div>
                        <h1 className="text-start text-3xl font-semibold ms-5 mb-3 pt-3">Consultoria Diamond</h1>
                        <p className="text-start ms-5 mb-10">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Obcaecati, dignissimos? </p>
                        <p className="text-5xl font-semibold text-verdeM mb-5"><span className="text-2xl font-light">R$</span>10.000,00</p>
                        <div className="" id="accordionExample">
                            <div>
                                <h2 className="mb-0" id="headingOne">
                                    <button
                                        className={`${activeElement === "element1" &&
                                            `text-primary dark:!text-primary-400`
                                            } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none text-azul`}
                                        type="button"
                                        onClick={() => handleClick("element1")}
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                    >
                                        <div className="flex items-end">
                                            <span className="me-1">Mais Informações</span>
                                            <span
                                                className={`${activeElement === "element1"
                                                    ? `rotate-[-180deg] -mr-1`
                                                    : `rotate-0 fill-[#212529]  dark:fill-white`
                                                    } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="h-5 w-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </button>
                                </h2>
                                <TECollapse
                                    show={activeElement === "element1"}
                                    className="!mt-0 !rounded-b-none !shadow-none"
                                >
                                    <div className="px-5 py-4">
                                        <div className="flex items-center mb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-verdeM">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>
                                            <span className="ms-2"><strong>3 meses</strong> de suporte</span>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-verdeM">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>
                                            <span className="ms-2"><strong>3 dias</strong> presenciais</span>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-verdeM">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>
                                            <span className="ms-2">Roteiro para o <strong>sucesso</strong></span>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-verdeM">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>
                                            <span className="ms-2">Atendimento por <strong>videochamada</strong></span>
                                        </div>
                                    </div>
                                </TECollapse>
                            </div>
                        </div>
                        <button className="btn bg-azul text-branco mb-5">Contratar</button>
                    </div>

                    <div className="bg-white rounded-xl" data-aos="fade-down" data-aos-duration="1000">
                        <div className="pt-3">
                            <svg class="size-8 me-3 ms-auto text-gold" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="12" cy="9" r="6" />  <polyline points="9 14.2 9 21 12 19 15 21 15 14.2" transform="rotate(-30 12 9)" />  <polyline points="9 14.2 9 21 12 19 15 21 15 14.2" transform="rotate(30 12 9)" /></svg>
                        </div>
                        <h1 className="text-start text-3xl font-semibold ms-5 mb-3 pt-3">Consultoria Gold</h1>
                        <p className="text-start ms-5 mb-10">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Obcaecati, dignissimos? </p>
                        <p className="text-5xl font-semibold text-verdeM mb-5"><span className="text-2xl font-light">R$</span>2.500,00</p>
                        <div className="" id="accordionExample">
                            <div>
                                <h2 className="mb-0" id="headingOne">
                                    <button
                                        className={`${activeElement === "element2" &&
                                            `text-primary dark:!text-primary-400`
                                            } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none text-azul`}
                                        type="button"
                                        onClick={() => handleClick("element2")}
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                    >
                                        <div className="flex items-end">
                                            <span className="me-1">Mais Informações</span>
                                            <span
                                                className={`${activeElement === "element2"
                                                    ? `rotate-[-180deg] -mr-1`
                                                    : `rotate-0 fill-[#212529]  dark:fill-white`
                                                    } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="h-5 w-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </button>
                                </h2>
                                <TECollapse
                                    show={activeElement === "element2"}
                                    className="!mt-0 !rounded-b-none !shadow-none"
                                >
                                    <div className="px-5 py-4">
                                        <div className="flex items-center mb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-verdeM">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>
                                            <span className="ms-2"><strong>15 dias</strong> de suporte</span>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-verdeM">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>
                                            <span className="ms-2">Roteiro para o <strong>sucesso</strong></span>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-verdeM">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>
                                            <span className="ms-2">Atendimento por <strong>videochamada</strong></span>
                                        </div>
                                    </div>
                                </TECollapse>
                            </div>
                        </div>
                        <button className="btn bg-azul text-branco mb-5">Contratar</button>
                    </div>

                    <div className="bg-white rounded-xl" data-aos="fade-down" data-aos-duration="800">
                        <div className="pt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8 me-3 ms-auto text-sky-500">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                            </svg>

                        </div>
                        <h1 className="text-start text-3xl font-semibold ms-5 mb-3 pt-3">E-book</h1>
                        <p className="text-start ms-5 mb-10">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Obcaecati, dignissimos? </p>
                        <p className="text-5xl font-semibold text-verdeM mb-5"><span className="text-2xl font-light">R$</span>29,90</p>
                        <div className="" id="accordionExample">
                            <div>
                                <h2 className="mb-0" id="headingOne">
                                    <button
                                        className={`${activeElement === "element3" &&
                                            `text-primary dark:!text-primary-400`
                                            } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none text-azul`}
                                        type="button"
                                        onClick={() => handleClick("element3")}
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                    >
                                        <div className="flex items-end">
                                            <span className="me-1">Mais Informações</span>
                                            <span
                                                className={`${activeElement === "element3"
                                                    ? `rotate-[-180deg] -mr-1`
                                                    : `rotate-0 fill-[#212529]  dark:fill-white`
                                                    } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="h-5 w-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </button>
                                </h2>
                                <TECollapse
                                    show={activeElement === "element3"}
                                    className="!mt-0 !rounded-b-none !shadow-none"
                                >
                                    <div className="px-5 py-4">
                                        <div className="flex items-center mb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-verdeM">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>
                                            <span className="ms-2">Acesso <strong>vitalício</strong></span>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-verdeM">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>
                                            <span className="ms-2">Versão <strong>digital</strong></span>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-verdeM">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>
                                            <span className="text-start ms-2">Dicas para <strong>aumentar o faturamento</strong></span>
                                        </div>
                                    </div>
                                </TECollapse>
                            </div>
                        </div>
                        <button className="btn bg-azul text-branco mb-5">Contratar</button>
                    </div>
                </div>
            </section>
        </main >
    )
}

export default Services;