import React from "react";
import { Link } from "react-router-dom";
import grafico from "../assets/grafico.png"
import gestao from "../assets/gestao.png"
import solucao from "../assets/solucao.png"
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
    React.useEffect(() => {
        AOS.init();
    }, [])
    return (
        <main className="App">
            <section className="m-auto p-10 bg-image md:grid md:grid-cols-1 md:gap-4">
                <div className="container-header" data-aos="fade-right" data-aos-duration="900">
                    <h1 className="bg-verdeMopacity p-2 shadow text-4xl font-semibold text-start mb-2">Eleve o nível dos seus negócios!</h1>
                    <p className="bg-verdeCopacity p-2 shadow text-start text-2xl font-light mb-5">Conheça nossos serviços, veja os resultados e surpreenda-se!</p>
                    <div className="text-start">
                        <button className="btn bg-verdeM">
                            <Link to='/servicos'>Começar</Link>
                        </button>
                    </div>
                </div>
            </section>

            <section className="container m-auto my-10">
                <div className="md:grid md:grid-cols-3 md:gap-10">
                    <div className="bg-verdeC rounded-xl shadow card mb-10 md:mb-0 md:p-5" data-aos="fade-left" data-aos-duration="800">
                        <div>
                            <img src={grafico} className="w-40 m-auto"></img>
                            <h3 className="text-start ms-5 text-3xl pt-5 mb-3">Promova seus ganhos:</h3>
                        </div>
                        <p className="text-start ms-5 pb-5">Mostramos o caminho para elevar seu faturamento mensal!</p>
                    </div>

                    <div className="bg-verdeC rounded-xl shadow card mb-10 md:mb-0 md:p-5" data-aos="fade-up" data-aos-duration="800">
                        <div>
                            <img src={gestao} className="w-40 m-auto pt-5"></img>
                            <h3 className="text-start ms-5 text-3xl pt-5 mb-3">Melhore sua gestão:</h3>
                        </div>
                        <p className="text-start ms-5 pb-5">Se você enfrenta problemas de gestão também podemos te ajudar!</p>
                    </div>

                    <div className="bg-verdeC rounded-xl shadow card md:p-5" data-aos="fade-right" data-aos-duration="800">
                        <div>
                            <img src={solucao} className="w-40 m-auto pt-5"></img>
                            <h3 className="text-start ms-5 text-3xl pt-5 mb-3">Análise de problema:</h3>
                        </div>
                        <p className="text-start ms-5 pb-5">Está enfrentando dificuldades mas não consegue identificar a causa? Analisamos e mostramos o caminho para o sucesso!</p>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Home;