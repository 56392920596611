import React from "react";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact = () => {
    React.useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className="">
            <section className="md:grid md:grid-cols-2 md:mb-5">
                <div className=" md:ms-20 md:mt-10" data-aos="fade-up" data-aos-duration="1000">
                    <h1 className="text-start ms-5 font-semibold text-4xl mt-5">Contate-nos</h1>
                    <p className="text-start ms-5 mt-3 p-1" >Acesse nosso <Link to="/faq" className="link">FAQ</Link> para dúvidas comuns, caso não resolva entre em contato preenchendo o formulário.</p>
                    <div className="mt-3">
                        <p className="font-semibold">Nos acompanhe nas redes sociais:</p>
                        <a href="https://www.instagram.com/valdineygoncalvs/" target="_blank">
                            <div className="flex justify-center items-center p-5 mt-5 mx-20 md:mx-56 rounded-xl border bg-verdeC insta shadow">
                                <svg class="size-8 text-red-500 me-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" /></svg>
                                <p>@valdineygoncalvs</p>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="p-10" data-aos="fade-up" data-aos-duration="800">
                    <div
                        class="mx-auto block max-w-md rounded-lg bg-verdeM p-6 shadow dark:bg-surface-dark">
                        <form>
                            {/* <!--Name input--> */}
                            <div class="relative mb-6" data-twe-input-wrapper-init>
                                <input
                                    type="text"
                                    class="peer block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                                    id="exampleInput7"
                                    placeholder="Name" />
                                <label
                                    for="exampleInput7"
                                    class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[twe-input-state-active]:-translate-y-[0.9rem] peer-data-[twe-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-300 dark:peer-focus:text-primary"
                                >Nome
                                </label>
                            </div>

                            {/* <!--Email input--> */}
                            <div class="relative mb-6" data-twe-input-wrapper-init>
                                <input
                                    type="email"
                                    class="peer block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                                    id="exampleInput8"
                                    placeholder="Email address" />
                                <label
                                    for="exampleInput8"
                                    class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[twe-input-state-active]:-translate-y-[0.9rem] peer-data-[twe-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-300 dark:peer-focus:text-primary"
                                >Email
                                </label>
                            </div>

                            {/* <!--Message textarea--> */}
                            <div class="relative mb-6" data-twe-input-wrapper-init>
                                <textarea
                                    class="peer block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                                    id="exampleFormControlTextarea13"
                                    rows="3"
                                    placeholder="Message"></textarea>
                                <label
                                    for="exampleFormControlTextarea13"
                                    class="pointer-events-none absolute left-3 top-0 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[twe-input-state-active]:-translate-y-[0.9rem] peer-data-[twe-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-300 dark:peer-focus:text-primary"
                                >Mensagem
                                </label>
                            </div>

                            {/* <!--Submit button--> */}
                            <button
                                type="submit"
                                class="inline-block w-full rounded bg-azul btn px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                                data-twe-ripple-init
                                data-twe-ripple-color="light">
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact;