import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';

import Home from './routes/Home';
import Contact from './routes/Contact';
import Services from './routes/Services';
import Faq from './routes/Faq'
import Error from './routes/Error';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/contato',
        element: <Contact />,
      },
      {
        path: '/servicos',
        element: <Services />,
      },
      {
        path: '/faq',
        element: <Faq />,
      },
      {
        path: 'contact',
        element: <Navigate to="/contato" />,
      },
      {
        path: 'about',
        element: <Navigate to="/sobre" />,
      },
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
