import React from "react";

const Error = () => {
    return (
        <div className="App">
            <h1>404</h1>
        </div>
    )
}

export default Error;