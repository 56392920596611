import { Link } from "react-router-dom";
import logo from "../assets/logo.png"

const Footer = () => {
    return (
        <footer className="footer bg-azul text-branco">
            <div className="md:grid md:grid-cols-3 md:gap-4 p-10 items-center">
                <div className="flex justify-center mb-5 md:mb-0">
                    <div>
                        <h6 className="font-semibold">Localização:</h6>
                        <a href="https://maps.app.goo.gl/XYEMneM8qhHEuxe96" className="footer-link">
                            <p>Praça N.S. Dos Remedios, 186</p>
                            <p>Campos de Cunha, Cunha - SP, 12540-000</p>
                        </a>
                    </div>
                </div>

                <div className="flex justify-center mb-5 md:mb-0">
                    <div className="text-start">
                        <h6 className="font-semibold">Links:</h6>
                        <ul className="">
                            <li>
                                <Link className="font-light footer-link" to='/'>Home</Link>
                            </li>
                            <li>
                                <Link className="font-light footer-link" to='/servicos'>Serviços</Link>
                            </li>
                            <li>
                                <Link className="font-light footer-link" to='/contato'>Contato</Link>
                            </li>
                            <li>
                                <Link className="font-light footer-link" to='/faq'>FAQ</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex justify-center">
                    <img src={logo} className="w-40"></img>
                </div>
            </div>
        </footer>
    )
}

export default Footer;